.contact-contact20 {
	display: flex;
	overflow: hidden;
	position: relative;
	align-items: center;
	flex-direction: column;
}
.contact-max-width {
	gap: var(--dl-space-space-twounits);
	display: flex;
	align-items: center;
	flex-direction: column;
}
.contact-section-title {
	gap: var(--dl-space-space-unit);
	width: auto;
	display: flex;
	max-width: 800px;
	align-items: center;
	flex-shrink: 0;
	flex-direction: column;
}
.contact-content1 {
	gap: var(--dl-space-space-oneandhalfunits);
	display: flex;
	align-self: stretch;
	align-items: center;
	flex-direction: column;
}
.contact-text3 {
	text-align: center;
}
.contact-row {
	gap: var(--dl-space-space-threeunits);
	display: flex;
	align-self: stretch;
	align-items: flex-start;
}
.contact-content2 {
	gap: var(--dl-space-space-oneandhalfunits);
	flex: 1;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.contact-contact-info1 {
	gap: var(--dl-space-space-oneandhalfunits);
	display: flex;
	align-self: stretch;
	align-items: center;
	flex-direction: column;
}
.contact-content3 {
	gap: var(--dl-space-space-unit);
	display: flex;
	align-self: stretch;
	align-items: stretch;
	flex-direction: column;
}
.contact-text4 {
	align-self: stretch;
	text-align: center;
}
.contact-text5 {
	text-align: center;
}
.contact-email {
	text-align: center;
	font-weight: bold;
}
.contact-content4 {
	gap: var(--dl-space-space-oneandhalfunits);
	flex: 1;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.contact-contact-info2 {
	gap: var(--dl-space-space-oneandhalfunits);
	display: flex;
	align-self: stretch;
	align-items: center;
	flex-direction: column;
}
.contact-content5 {
	gap: var(--dl-space-space-unit);
	display: flex;
	align-self: stretch;
	align-items: stretch;
	flex-direction: column;
}
.contact-text6 {
	align-self: stretch;
	text-align: center;
}
.contact-text7 {
	text-align: center;
}
.contact-phone {
	text-align: center;
	font-weight: bold;
}
.contact-content6 {
	gap: var(--dl-space-space-oneandhalfunits);
	flex: 1;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.contact-contact-info3 {
	gap: var(--dl-space-space-oneandhalfunits);
	display: flex;
	align-self: stretch;
	align-items: center;
	flex-direction: column;
}
.contact-content7 {
	gap: var(--dl-space-space-unit);
	display: flex;
	align-self: stretch;
	align-items: stretch;
	flex-direction: column;
}
.contact-text8 {
	align-self: stretch;
	text-align: center;
}
.contact-text9 {
	text-align: center;
}
.contact-address {
	text-align: center;
	font-weight: bold;
}
@media (max-width: 767px) {
	.contact-row {
		flex-direction: column;
	}
}
@media (max-width: 479px) {
	.contact-row {
		align-items: stretch;
	}
}
