.navbar-container {
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;
	background-color: var(--dl-color-theme-neutral-light);
}
.navbar-navbar-interactive {
	width: 100%;
	display: flex;
	max-width: var(--dl-size-size-maxwidth);
	align-items: center;
	padding-top: var(--dl-space-space-twounits);
	padding-left: var(--dl-space-space-threeunits);
	padding-right: var(--dl-space-space-threeunits);
	padding-bottom: var(--dl-space-space-twounits);
	justify-content: space-between;
}
/* Hide desktop menu on mobile */
@media (max-width: 768px) {
	.navbar-container {
		display: none;
	}
}

/* Show mobile menu only on mobile devices */
@media (min-width: 769px) {
	.navbar-mobile-menu {
		display: none;
	}
}

.navbar-image1 {
	height: 3rem;
}
.navbar-desktop-menu {
	flex: 1;
	display: flex;
	justify-content: end;
}
.navbar-links1 {
	gap: var(--dl-space-space-twounits);
	flex: 1;
	display: flex;
	align-items: center;
	margin-left: var(--dl-space-space-twounits);
	flex-direction: row;
	justify-content: flex-start;
}
.navbar-buttons1 {
	gap: var(--dl-space-space-twounits);
	display: flex;
	align-items: center;
	margin-left: var(--dl-space-space-twounits);
}
.navbar-action11 {
	display: flex;
	flex-direction: row;
}
.navbar-action21 {
	display: flex;
	flex-direction: row;
}
.navbar-burger-menu {
	display: none;
}
.navbar-icon1 {
	width: var(--dl-size-size-xsmall);
	height: var(--dl-size-size-xsmall);
}
.navbar-mobile-menu {
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100vh;
	display: none;
	padding: var(--dl-space-space-twounits);
	z-index: 100;
	position: absolute;
	flex-direction: column;
	background-color: var(--dl-color-theme-neutral-light);
}
.navbar-nav {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
.navbar-top {
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: var(--dl-space-space-threeunits);
	justify-content: space-between;
}
.navbar-logo {
	height: 3rem;
}
.navbar-close-menu {
	display: flex;
	align-items: center;
	justify-content: center;
}
.navbar-icon3 {
	width: var(--dl-size-size-xsmall);
	height: var(--dl-size-size-xsmall);
}
.navbar-links2 {
	gap: var(--dl-space-space-unit);
	flex: 0 0 auto;
	display: flex;
	align-self: flex-start;
	align-items: flex-start;
	flex-direction: column;
}
.navbar-buttons2 {
	gap: var(--dl-space-space-twounits);
	display: flex;
	margin-top: var(--dl-space-space-twounits);
	align-items: center;
}
@media (max-width: 767px) {
	.navbar-navbar-interactive {
		padding-left: var(--dl-space-space-twounits);
		padding-right: var(--dl-space-space-twounits);
	}
	.navbar-desktop-menu {
		display: none;
	}
	.navbar-burger-menu {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
@media (max-width: 479px) {
	.navbar-navbar-interactive {
		padding: var(--dl-space-space-unit);
	}
	.navbar-mobile-menu {
		padding: var(--dl-space-space-unit);
	}
}
