.footer-footer1 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.footer-max-width {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-content {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius4);
}
.footer-newsletter {
  gap: 24px;
  width: 500px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer-image1 {
  height: 2rem;
}
.footer-actions {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer-form {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-shrink: 0;
}
.footer-container {
  width: 365px;
  display: flex;
  align-items: flex-start;
}
.footer-text-input {
  gap: 8px;
  width: 100%;
  height: 32px;
  display: flex;
  font-size: 16px;
  box-sizing: content-box;
  text-align: left;
  align-items: center;
  font-family: Roboto;
  font-weight: 400;
  background-color: transparent;
}
.footer-content2 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  height: auto;
  font-size: 12px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.footer-links {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-end;
}
.footer-column1 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer-footer-links1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer-column2 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer-footer-links2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer-column3 {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer-social-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer-link14 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer-link15 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer-link16 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer-link17 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer-link18 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.footer-credits {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.footer-row {
  gap: 64px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
.footer-footer-links3 {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
@media(max-width: 991px) {
  .footer-newsletter {
    width: 300px;
  }
  .footer-form {
    width: 100%;
    flex-direction: column;
  }
  .footer-container {
    width: 100%;
  }
  .footer-text-input {
    width: 100%;
    padding: var(--dl-space-space-halfunit);
  }
  .footer-button {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 767px) {
  .footer-content {
    flex-direction: column;
  }
  .footer-newsletter {
    width: 100%;
  }
  .footer-form {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }
  .footer-container {
    width: 100%;
  }
  .footer-button {
    width: 208px;
  }
  .footer-links {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  .footer-column1 {
    align-items: center;
  }
  .footer-column1-title {
    text-align: center;
  }
  .footer-footer-links1 {
    align-self: center;
  }
  .footer-column2 {
    align-items: center;
  }
  .footer-column2-title {
    text-align: center;
  }
  .footer-footer-links2 {
    align-self: center;
  }
  .footer-column3 {
    align-items: center;
  }
  .footer-social-link1-title {
    text-align: center;
  }
  .footer-social-links {
    align-self: center;
  }
  .footer-row {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .footer-actions {
    width: 100%;
  }
  .footer-form {
    width: 100%;
    flex-direction: column;
  }
  .footer-container {
    width: 100%;
  }
  .footer-button {
    width: 100%;
  }
  .footer-links {
    flex-direction: column;
  }
  .footer-column1 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer-footer-links1 {
    align-items: center;
    justify-content: center;
  }
  .footer-column2 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer-footer-links2 {
    align-items: center;
    justify-content: center;
  }
  .footer-column3 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer-social-links {
    align-items: center;
    justify-content: center;
  }
  .footer-credits {
    gap: 0;
  }
  .footer-row {
    align-items: center;
    justify-content: center;
  }
  .footer-footer-links3 {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
