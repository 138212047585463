.steps-container1 {
	width: 100%;
	display: flex;
	position: relative;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}
.steps-max-width {
	gap: var(--dl-space-space-fourunits);
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: row;
}
.steps-container2 {
	align-items: start;
}
.steps-section-header {
	gap: var(--dl-space-space-oneandhalfunits);
	top: 10%;
	display: flex;
	position: sticky;
	align-items: flex-start;
	flex-direction: column;
}
.steps-actions {
	gap: var(--dl-space-space-unit);
	display: flex;
	align-items: flex-start;
}
.steps-container3 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	margin: 20px;
}
.steps-container4 {
	top: 10%;
	position: sticky;
	transform: rotate(-2deg);
	margin-bottom: var(--dl-space-space-twounits);
	background-color: var(--dl-color-theme-accent1);
}
.steps-text14 {
	text-align: center;
}
.steps-text15 {
	top: var(--dl-space-space-unit);
	right: var(--dl-space-space-unit);
	position: absolute;
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
}
.steps-container5 {
	top: 10%;
	position: sticky;
	transform: rotate(2deg);
	margin-bottom: var(--dl-space-space-twounits);
	background-color: var(--dl-color-theme-accent2);
}
.steps-text17 {
	text-align: center;
}
.steps-text18 {
	top: var(--dl-space-space-unit);
	right: var(--dl-space-space-unit);
	position: absolute;
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
}
.steps-container6 {
	top: 10%;
	position: sticky;
	transform: rotate(-2deg);
	margin-bottom: var(--dl-space-space-twounits);
	background-color: var(--dl-color-theme-accent1);
}
.steps-text20 {
	text-align: center;
}
.steps-text21 {
	top: var(--dl-space-space-unit);
	right: var(--dl-space-space-unit);
	position: absolute;
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
}
.steps-container7 {
	top: 10%;
	position: sticky;
	transform: rotate(2deg);
	background-color: var(--dl-color-theme-accent2);
}
.steps-text23 {
	text-align: center;
}
.steps-text24 {
	top: var(--dl-space-space-unit);
	right: var(--dl-space-space-unit);
	position: absolute;
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
}
@media (max-width: 991px) {
	.steps-max-width {
		flex-direction: column;
	}
}
@media (max-width: 767px) {
	.steps-section-header {
		position: static;
		margin-bottom: var(--dl-space-space-twounits);
	}
	.steps-actions {
		width: 100%;
		align-self: flex-start;
	}
	.steps-container4 {
		width: 100%;
	}
	.steps-container5 {
		width: 100%;
	}
	.steps-container6 {
		width: 100%;
	}
	.steps-container7 {
		width: 100%;
	}
}
@media (max-width: 479px) {
	.steps-button {
		width: 100%;
	}
}
